import React from "react";
import Layout from "../layout";
import Headline from "../components/Headline";
import TextBlock from "../components/TextBlock";
import { Category } from "@material-ui/icons";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
//import  background  from "../img/warehouseimg2.jpg"
//import kuva3  from '../img/varasto_kuva2.png'

export default function Varasto() {
  // Load images
  // Specify sizing, quality etc. here
  const imgdata = useStaticQuery(graphql`{
  bg: file(relativePath: {eq: "warehouseimg2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, quality: 90)
    }
  }
  }
  `)
  const bgImageData = imgdata.bg;

  return <>
  <SEO title="Varasto" description="Merppi päivittää varastosaldot automaattisesti"/>
  <Layout>
      <Headline HeadIcon={Category} header="Varasto" backgroundImageData={bgImageData} />
  <Container className="page_container">
  
    <Row>
      <Col md="4">
        <TextBlock
          Header="Varasto"
          Paragraph="Merppi päivittää varastosaldot automaattisesti. Manuaalinen muokkausmahdollisuus tarvittaessa. Määriteltävissä olevat hälytysrajat kertovat, millon tavaraa tarvitaan lisää. Raportointi ennakoimaan tulevaa täydennystarvetta. Laskee halutulle ajankodalle tarpeen huomioiden tilauskannassa olevat tilaukset."
        ></TextBlock>

        <TextBlock
          Header="Ostotilaukset"
          Paragraph="Toimittajarekisteri, joille ostotilaukset järjestelmästä nimekkeillä ja tuotekoodeilla. Vastaanotto joko osissa tai kerralla, jolloin tavara kirjaantuu varastoon."
        ></TextBlock>
      </Col>
      <Col md="8">
        <StaticImage src="../../content/images/varasto_kuva2.png" className="kuva" alt="Varasto" layout="fullWidth"/>
      </Col>
    </Row>
  </Container>
  </Layout>
</>;
}
